
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { CargoController } from "@/app/ui/controllers/CargoController";
import OverlayPanel from "primevue/overlaypanel";
import {
  ellipsisString,
  firstCapitalize,
  formatDate,
  formatTimeCustom,
  getDifferenceDate,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { FlightDetail } from "@/domain/entities/NGen";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { gtmCargoData } from "@/app/ui/views/cargo/modules/gtm";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { defineAsyncComponent } from "vue";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { ReadyToCargo } from "@/domain/entities/ReadyToCargo";
import { CargoInputData } from "@/domain/entities/Cargo";


const CargoProcessCargo = defineAsyncComponent({
  loader: () =>
    import(
      "@/app/ui/views/cargo/booking-cargo/third-step/cargo-process-cargo.vue"
    )
});
@Options({
  emits: ["select-transit"],
  components: {
    Tooltip,
    OverlayPanel,
    Skeleton,
    CargoProcessCargo
  },
  props: {
    width: {
      default: "w-full",
      type: String
    },
    isResponsiveTooltip: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    data: {
      default: null,
      type: Object
    }
  }
})
export default class ScheduleCard extends Vue {
  firstCapitalize(str: string) {
    if (str) {
      return firstCapitalize(str.toLowerCase());
    }
    return "";
  }

  refs: any = "";

  mounted() {
    this.refs = this.$refs;
  }

  get isEditCutOff() {
    return this.isAllowEditCutoff;
  }

  get isPlaneAuto() {
    return (
      this.form.cargoType === "plane" && this.form.cargoTypeStatus === "auto"
    );
  }

  get isAllowEditCutoff(){
    const isEditRtcCutoff = ReadyToCargoController.flowBooking === 'rtc-edit' && this.isPlaneAuto && this.cutOffTime.hours !== undefined && this.cutOffTime.minutes !== undefined
    return isEditRtcCutoff;
  } 

  get cutOffTime() {
    return {hours: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(":")[0], minutes: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(":")[1] };
  }

  onChoosePlane() {
    if (this.props.isLoading) {
      return;
    }
    CargoController.setFlightData(this.detailData);
    if (Number(this.form.transit)) {
      const gtmChooseFlight = gtmCargoData({
        type: this.form.cargoType,
        isAuto: true,
        gtmData: {
          flightNumber: this.detailData.FlightNo,
          departureDateTime: this.detailData.DepartureDateTime,
          arrivalDateTime: this.detailData.ArrivalDateTime
        }
      }).chooseFlight;

      dataLayer(gtmChooseFlight.eventName, gtmChooseFlight.property, []);
    } else {
      const gtmClickFlightList = gtmCargoData({
        type: this.form.cargoType,
        isAuto: true,
        gtmData: {
          flightNumber: this.detailData.FlightNo,
          departureDateTime: this.form.estDepartureDate
        }
      }).clickFlightList;

      dataLayer(gtmClickFlightList.eventName, gtmClickFlightList.property, []);
    }
    if (this.form.flightsData.length === this.form.airports.length - 1) {

      if(this.flowBooking.includes("rtc") || (this.flowBooking === "rebooking" && this.$route.query.rcId && this.isFromRebookingRTC)) {
        const today = { hours: formatDate(new Date())?.split(",")[1]?.split(":")[0]?.trim(), minutes: formatDate(new Date())?.split(",")[1]?.split(":")[1]?.replace(/\bwib\b|\bwita\b|\bwit\b|\bgmt\b/gi, '')?.trim()}
        const isAllowed = ((+this.cutOffTime.hours < +today.hours) || (+this.cutOffTime.hours === +today.hours && +this.cutOffTime.minutes < +today.minutes)) && CargoController.progressValue !== - 1;
        if (!(this.isEditCutOff && isAllowed)) {
          CargoController.setProgressValue(0);
          CargoController.setIsChangeCargoType(true);
        }
        if (this.isEditCutOff && isAllowed) {
          this.refs.bookingCargo.onSave();
        }
      } else {
        CargoController.setShowBookingForm(true);
        CargoController.setFillForm(true);
        CargoController.setProgressValue(2);
      }
      CargoController.setShowPlaneSchedule(false);
      CargoController.setOnChangeVehicleNumber(this.detailData.FlightNo);
    } else {
      this.$emit("select-transit");
    }
  }


  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  get isFromRebookingRTC() {
    return CargoController.isRebookingFromRTC;
  }

  isTooltip = false;
  props: any = this.$props;
  toggleTooltipHeader(event: any) {
    if (this.isTooltip) this.isTooltip = false;
    else this.isTooltip = true;

    if (this.props.isResponsiveTooltip) {
      const refs: any = this.$refs;
      refs["opTooltip"]?.toggle(event);
    }
  }

  get detailData(): FlightDetail {
    return this.props.data;
  }

  get form() {
    return CargoController.form;
  }

  // date time
  get departureDateTime() {
    return formatTimeCustom({
      date: this.detailData.DepartureDateTime,
      isSecond: false
    });
  }
  get arrivalDateTime() {
    return formatTimeCustom({
      date: this.detailData.ArrivalDateTime,
      isSecond: false
    });
  }
  get differenceDate() {
    return getDifferenceDate(
      this.detailData.DepartureDateTime,
      this.detailData.ArrivalDateTime
    );
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  //airport
  get departureAirport() {
    return (
      this.form.airports.find(
        item => item.airportCode === this.detailData.Route.split("-")[0]
      ) || {
        airportCode: "",
        airportName: ""
      }
    );
  }
  get arrivalAirport() {
    return (
      this.form.airports.find(
        item => item.airportCode === this.detailData.Route.split("-")[1]
      ) || {
        airportCode: "",
        airportName: ""
      }
    );
  }

  ellipsisString(value: string) {
    return ellipsisString(value, 40);
  }
}
