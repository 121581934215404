
import { Vue, Options, prop } from "vue-class-component";
import OverlayPanel from "primevue/overlaypanel";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { getDifferenceDate } from "@/app/infrastructures/misc/Utils";

class Props {
  index = prop<any>({
    type: Number,
    default: 1
  });
  data = prop<any>({
    default: {}
  });
  isWatchProgress = prop<boolean>({
    default: false
  });
}
@Options({
  emits: ["request"],
  components: {
    OverlayPanel
  }
})
export default class HeaderScheduleCard extends Vue.with(Props) {
  get styleCard() {
    switch (this.progressData) {
      case "done":
        return "bg-green-lp-100";
      case "opacity":
        return "bg-red-lp-300 opacity-50";
      default:
        return "bg-red-lp-300";
    }
  }
  get styleTextNumber() {
    if (this.progressData === "done") return "text-green-lp-100";
    else return "text-red-lp-300";
  }
  get progressData() {
    if (!this.isWatchProgress) {
      if (this.index === this.form.flightsData.length) return "active";
      else if (this.index < this.form.flightsData.length) return "done";
      return "opacity";
    } else {
      if (this.index === 0) return "active";
      return "opacity";
    }
  }
  get form() {
    return CargoController.form;
  }
  isTooltip = false;
  toggleTooltipHeader(event: any) {
    this.isTooltip = !this.isTooltip;
    const refs: any = this.$refs;
    refs["opTooltip"].toggle(event);
  }

  changeFlightSchedule() {
    this.form.flightsData.splice(
      this.index,
      this.form.flightsData.length - this.index
    );
    const newFlightDataToLocalStorage = this.form.flightsData;
    CargoController.setEditFlightData(newFlightDataToLocalStorage); // this code is for update localstorage rtc-flight-data when user change plane schedule
    this.$emit("request");
  }

  get tooltipFLightData() {
    return this.form.flightsData[this.index];
  }
  get differenceDate() {
    return getDifferenceDate(
      this.tooltipFLightData.DepartureDateTime,
      this.tooltipFLightData.ArrivalDateTime
    );
  }

  moment(val: any) {
    return this.$moment(val);
  }

  get isCenterVertical(): boolean {
    return (
      !this.data?.origin &&
      !this.data?.destination &&
      !this.data?.DepartureDateTime &&
      !this.data?.ArrivalDateTime
    );
  }
}
